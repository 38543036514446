import "../scss/main.scss";
import $ from 'jquery';
import 'slick-carousel';
import PureCounter from '@srexi/purecounterjs';

jQuery(function(){    
    actionsScroll();
    $(window).on('scroll', function(){    
        actionsScroll();
    });

    if( $('#navfixe').length != 0 || $('.formation--navfixe').length != 0 ){
        $('body').addClass('has-navfixe');
    }

    // INIT ALL FUNCTIONS
    imgBack();
    sliders();
    gestionMenu();
    accordeon();
    tabsConstruction();
    counter();
    animationScroll();
});

function actionsScroll(){
    var scrolled = $(window).scrollTop();

    if(scrolled > 0){
        $('body').addClass('scrolling'); 
    } else {
        $('body').removeClass('scrolling');
    }

    if(scrolled > $(window).innerHeight()*0.65){
        $('#navfixe').addClass('nav-visible'); 
    }else{
        $('#navfixe').removeClass('nav-visible'); 
    }
}

function imgBack(){
    $('.backImg').each(function(){
        var urlImgCurrent = $(this).children('img').attr('src');
        $(this).attr('style', 'background-image:url('+urlImgCurrent+'); background-position: 50% 50%; background-size: cover; background-repeat: no-repeat;');
        $(this).find('img').remove();			
    });
}

function gestionMenu(){
    $('#mega-menu-item-64').on('mouseenter', function(){
        $('#masthead').addClass('etudiantActive');
    }).on('mouseleave', function(){
        $('#masthead').removeClass('etudiantActive');
    });

    $('#mega-menu-item-65').on('mouseenter', function(){
        $('#masthead').addClass('proActive');
    }).on('mouseleave', function(){
        $('#masthead').removeClass('proActive');
    });

    $('#mega-menu-item-66').on('mouseenter', function(){
        $('#masthead').addClass('diplomeActive');
    }).on('mouseleave', function(){
        $('#masthead').removeClass('diplomeActive');
    });

    $('#btNav').on('click', function(){
        $(this).toggleClass('open');
        $('.col--navigation-mobile').toggleClass('open');
    });
}

function accordeon(){
    // PAGE FORMATION
    $('.formation--deroule-bloc .bloc-contenu').hide();
    $(this).children('.material-symbols-sharp').text('add');

    // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
    // et on remplace l'élément span qu'ils contiennent par un lien :
    $(".formation--deroule-bloc .bloc-title").click( function () {
        // Si le sous-menu était déjà ouvert, on le referme :
        if ($(this).next(".bloc-contenu:visible").length != 0) {
            $(this).next(".bloc-contenu").slideUp("normal");
            $(this).removeClass('active');
            $(this).children('.material-symbols-sharp').text('add');
        }
        // Si le sous-menu est caché, on ferme les autres et on l'affiche :
        else {
            $(".formation--deroule-bloc .bloc-contenu").slideUp("normal");
            $(".formation--deroule-bloc .bloc-title").removeClass('active');
            $('.material-symbols-sharp').text('add');
            $(this).next(".bloc-contenu").slideDown("normal");
            $(this).addClass('active');
            $(this).children('.material-symbols-sharp').text('remove');
        }
        // On empêche le navigateur de suivre le lien :
        return false;
    });

    // POSTULER À L'EMD
    $('.section--questions .question--reponse').hide();

    // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
    // et on remplace l'élément span qu'ils contiennent par un lien :
    $(".section--questions .question--question").on('click', function () {
        // Si le sous-menu était déjà ouvert, on le referme :
        if ($(this).next(".question--reponse:visible").length != 0) {
            $(this).next(".question--reponse").slideUp("normal");
            $(this).removeClass('active');
        }
        // Si le sous-menu est caché, on ferme les autres et on l'affiche :
        else {
            $(".section--questions .question--reponse").slideUp("normal");
            $(".section--questions .question--question").removeClass('active');
            $(this).next(".question--reponse").slideDown("normal");
            $(this).addClass('active');
        }
        // On empêche le navigateur de suivre le lien :
        return false;
    });

    // BLOC ACCORDEON
    $('.bloc--accordeon .bloc--content').hide();
    // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
    // et on remplace l'élément span qu'ils contiennent par un lien :
    $(".bloc--accordeon .bloc--title").on('click', function () {
        // Si le sous-menu était déjà ouvert, on le referme :
        if ($(this).next(".bloc--content:visible").length != 0) {
            $(this).next(".bloc--content").slideUp("normal");
            $(this).removeClass('active');
            $(this).children('.open').toggleClass('fa-plus').toggleClass('fa-minus');
        }
        // Si le sous-menu est caché, on ferme les autres et on l'affiche :
        else {
            $(".bloc--accordeon .bloc--content").slideUp("normal");
            $(".bloc--accordeon .bloc--title").removeClass('active');
            $(".bloc--accordeon .bloc--title").children('.open').addClass('fa-plus').removeClass('fa-minus');
            $(this).next(".bloc--content").slideDown("normal");
            $(this).addClass('active');
            $(this).children('.open').toggleClass('fa-plus').toggleClass('fa-minus');
        }
        // On empêche le navigateur de suivre le lien :
        return false;
    });

    //BLOC ACCORDEON SIMPLE
    $('.bloc--points .point--contenu').hide();

    // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
    // et on remplace l'élément span qu'ils contiennent par un lien :
    $(".bloc--points .point--intitule").on('click', function () {
        // Si le sous-menu était déjà ouvert, on le referme :
        if ($(this).next(".point--contenu:visible").length != 0) {
            $(this).next(".point--contenu").slideUp("normal");
            $(this).removeClass('active');
        }
        // Si le sous-menu est caché, on ferme les autres et on l'affiche :
        else {
            $(".bloc--points .point--contenu").slideUp("normal");
            $(".bloc--points .point--intitule").removeClass('active');
            $(this).next(".point--contenu").slideDown("normal");
            $(this).addClass('active');
        }
        // On empêche le navigateur de suivre le lien :
        return false;
    });

    if($(window).width() <= 760){
        //BLOC ACCORDEON POSTULER
        $('.bloc--onglets-dates .tabs-content .tab-content').hide();

        // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
        // et on remplace l'élément span qu'ils contiennent par un lien :
        $(".bloc--onglets-dates .tabs-content .tab-link-mobile").on('click', function () {
            // Si le sous-menu était déjà ouvert, on le referme :
            if ($(this).next(".tab-content:visible").length != 0) {
                $(this).next(".tab-content").slideUp("normal");
                $(this).removeClass('active');
            }
            // Si le sous-menu est caché, on ferme les autres et on l'affiche :
            else {
                $(".tabs-content .tab-content").slideUp("normal");
                $(".tabs-content .tab-link-mobile").removeClass('active');
                $(this).next(".tab-content").slideDown("normal").css('opacity', 1);
                $(this).next(".tab-content").slideDown("normal");
                $(this).addClass('active');
            }
            // On empêche le navigateur de suivre le lien :
            return false;
        });
    }
}

function tabsConstruction(){

    var hauteurMax = 0;
    jQuery('.tab-content').each(function(i){
        if( $(this).innerHeight() >= hauteurMax ){
            hauteurMax = $(this).innerHeight();
        }
    });

    jQuery('.formation--nav .tabs li .tab').on('click', function(e){
        e.preventDefault();

        if( !jQuery(this).parent('li').hasClass('active') ){
            var tab = jQuery(this).attr('data');

            /* On suprime la class active de tous les contenus */
            jQuery('.tab-content').removeClass('active');
            jQuery('.tab-content').hide().delay( 200 );

            /* On supprime la classe pour tous les liens */
            jQuery('.tabs li').removeClass('active');

            /* On ajoute la classe active à l'onglet qui doit être visible */
            jQuery(tab).addClass('active');
            jQuery(tab).show();

            /* On ajoute la classe pour le lien sur lequel l'internaute vient de cliquer */
            jQuery(this).parent('li').addClass('active');

            return false;
        }
    });

    jQuery('.formation--ancres .bt').on('click', function(e){
        var tabScroll= jQuery(this).attr('href');
        var tab = jQuery(this).attr('data');
        var tabOnglet = jQuery(this).attr('dataOnglet');
        var tabTarget = tabOnglet - 1;

        /* On suprime la class active de tous les contenus */
        jQuery('.tab-content').removeClass('active');
        jQuery('.tab-content').hide().delay( 200 );

        /* On supprime la classe pour tous les liens */
        jQuery('.tabs li').removeClass('active');

        /* On ajoute la classe active à l'onglet qui doit être visible */
        jQuery(tab).addClass('active').show();

        /* On ajoute la classe pour le lien sur lequel l'internaute vient de cliquer */
        jQuery('.formation--nav .tabs li:eq('+tabTarget+')').addClass('active');

        $('html, body').delay(400).animate({scrollTop: jQuery(tabScroll).offset().top - $('#masthead').innerHeight() - 18 }, "slow");
        return false;
    });

    jQuery('.bloc--onglets-dates .tabs li .tab-link').on('click', function(e){
        e.preventDefault();

        if( !jQuery(this).parent('li').hasClass('active') ){
            $('html, body').animate({scrollTop: $('.tabs').offset().top - $('#masthead').innerHeight() });

            var tab = jQuery(this).attr('data');

            /* On suprime la class active de tous les contenus */
            jQuery('.tab-content').removeClass('active');
            jQuery('.tab-content').hide().delay( 200 );

            /* On supprime la classe pour tous les liens */
            jQuery('.tabs li').removeClass('active');

            /* On ajoute la classe active à l'onglet qui doit être visible */
            jQuery(tab).addClass('active');
            jQuery(tab).show();

            /* On ajoute la classe pour le lien sur lequel l'internaute vient de cliquer */
            jQuery(this).parent('li').addClass('active');

            return false;
        }
    });

}

function counter() {
    new PureCounter();
}

function sliders(){

    $('.bloc--events .grille-annonce').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        dots: true,
        slidesToScroll: 1,
        slidesToShow: 2,
        infinite: true,
        prevArrow: '<span class="slide-prev material-symbols-sharp">navigate_before</span>',
        nextArrow: '<span class="slide-next material-symbols-sharp">navigate_next</span>',
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                }
            },
        ]
    });

    $('.bloc--actus .grille-annonce').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        dots: true,
        slidesToScroll: 1,
        slidesToShow: 2,
        infinite: true,
        prevArrow: '<span class="slide-prev material-symbols-sharp">navigate_before</span>',
        nextArrow: '<span class="slide-next material-symbols-sharp">navigate_next</span>',
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                }
            },
        ]
    });

    $('.bloc--diapo, .bloc--diapo-formation').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: false,
        dots: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        infinite: true,
        fade: true,
        responsive: [
            {
                breakpoint: 600
            },
        ]
    });

    $('.bloc--slider').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: true,
        dots: true,
        slidesToScroll: 1,
        slidesToShow: 10,
        infinite: true,
        centerPadding: '18px',
        prevArrow: '<span class="slide-prev material-symbols-sharp">navigate_before</span>',
        nextArrow: '<span class="slide-next material-symbols-sharp">navigate_next</span>',
        responsive: [
            {
                breakpoint: 1129,
                settings: {
                    slidesToShow: 7,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 400,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.bloc--diapositive').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: true,
        dots: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        infinite: true,
        prevArrow: '<span class="slide-prev material-symbols-sharp">navigate_before</span>',
        nextArrow: '<span class="slide-next material-symbols-sharp">navigate_next</span>',
        responsive: [
            {
                breakpoint: 600,
                settings:{
                    arrows: false,
                }
            },
        ]
    });

    $('.bloc--sliderinfos').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: true,
        dots: true,
        slidesToScroll: 1,
        slidesToShow: 3,
        infinite: true,
        prevArrow: '<span class="slide-prev material-symbols-sharp">navigate_before</span>',
        nextArrow: '<span class="slide-next material-symbols-sharp">navigate_next</span>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]
    });

    $('.bloc--temoignage').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        dots: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        infinite: true,
        centerPadding: '18px',
        prevArrow: '<span class="slide-prev material-symbols-sharp">navigate_before</span>',
        nextArrow: '<span class="slide-next material-symbols-sharp">navigate_next</span>',
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                }
            },
        ]
    });
}

function animationScroll(){
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.defaults({
        toggleActions: "restart pause resume pause"
    });

    if( $('.elem--anim-opacity').length != 0 ){
        let scrollAnimFade = gsap.utils.toArray('.elem--anim-opacity');
        scrollAnimFade.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                duration: 0.5
            });
        });
    }
 
    if( $('.title-anim').length != 0 ){
        let scrollAnimTitle = gsap.utils.toArray('.title-anim');
        scrollAnimTitle.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                duration: 0.5
            });
        });
    }

    if( $('.elem--anim-top').length != 0 ){
        let scrollAnimtop = gsap.utils.toArray('.elem--anim-top');
        scrollAnimtop.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                y: 90,
                duration: 0.5
            });

            //gsap.staggerFrom(item, 0.5, { autoAlpha:0 }, 0.5);
        });  
    }
    
    if( $('.quinconce-parallax .img-parallax').length != 0 ){
        gsap.to(".quinconce-parallax .img-parallax", {
            scrollTrigger: {
                start: "top bottom",
                end: "bottom top",
                markers: false,
                scrub: true
            }, 
            y: -ScrollTrigger.maxScroll(window) * 0.1,
            ease: "none"
        });
    }

    if( $('.js-anim-image .back, .js-anim-image img').length != 0 ){
        gsap.to(".js-anim-image .back, .js-anim-image img", {
            scrollTrigger: {
                start: "top bottom",
                end: "bottom top",
                markers: false,
                scrub: true
            }, 
            autoAlpha: 1,
            y: 1,
            ease: "none"
        });
    }

    if( $('.elem--anim-enterOpaciy').length != 0 ){
        gsap.to(".elem--anim-enterOpaciy", 0.5, { autoAlpha: 1 });
    }

    if( $('.elem--anim-enter').length != 0 ){
        gsap.to(".elem--anim-enter", 0.5, { autoAlpha: 1, y: 0 });
    }
}